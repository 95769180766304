import styled, { keyframes } from "styled-components";

// Criação da animação
const slideIn = keyframes`
  from {
    opacity: 0;
    transform: translateX(-50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const Wrapper = styled.div`
  display: flex;
  gap: 32px;
  max-width: 1216px;
  margin: 0 auto 40px;

  @media all and (max-width: 1140px) {
    flex-direction: column;
    padding: 0 40px;
  }
`;

export const Col = styled.div`
  flex: 1 0;
  display: flex;
  animation: ${slideIn} 0.5s ease-in-out ${({ delay }) => delay || "0s"}
    forwards;
  opacity: 0; /* Começa invisível */
`;
