import { useModalState } from "../containers/ModalProvider";
import { useCallback } from "react";
import { isMobile } from "is-mobile";
import { navigate } from "gatsby";
import { ActionModals } from "../containers/ModalRoot/module";

export const useSignup = ({ onOpen }) => {
  const [, dispatch] = useModalState();

  const openSignup = useCallback(
    (e) => {
      e.preventDefault();

      if (isMobile()) {
        navigate("/cadastro");
        return null;
      }

      dispatch(ActionModals.openModalSignup());

      if (onOpen) onOpen();
    },
    [dispatch, onOpen]
  );

  return { openSignup };
};
