import styled from "styled-components";
import ButtonCustom from "components/Button";

export const Wrapper = styled.div`
  position: relative;
  background: #fefefe;
  box-shadow: 0 20px 56px rgba(30, 31, 91, 0.06);
  border-radius: 4px;
  padding: 32px;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Title = styled.div`
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  color: #0060c3;
  margin-bottom: 20px;
`;

export const Text = styled.div`
  font-weight: 500;
  font-size: 17px;
  line-height: 27px;
  color: #211f20;
  margin-bottom: 20px;
`;

export const Price = styled.div`
  color: #211f20;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;

  strong {
    color: inherit;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
  }
`;

export const PriceInfo = styled.div`
  font-size: 12px;

  svg {
    vertical-align: middle;
    font-size: 18px;
  }

  del {
    font-weight: 700;
  }

  strong {
    color: #0060c3;
  }
`;

export const List = styled.ul`
  list-style: none;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  line-height: 24px;
  color: #4a5161;

  li > svg {
    vertical-align: middle;
    margin-top: -2px;
    margin-right: 4px;
    font-size: 18px;
  }

  span {
    color: inherit;
    cursor: pointer;

    svg {
      vertical-align: middle;
      margin-left: 5px;
    }
  }

  li {
    font-size: 15px;
  }
`;

export const Button = styled(ButtonCustom)`
  width: 100%;
  margin-top: 40px;
`;

export const Spacer = styled.div`
  flex: 1 0;
`;

export const Featured = styled(Wrapper)`
  background: #0060c3;
  color: #fff;

  ${Title},
  ${Price},
  ${Text},
  ${PriceInfo} strong,
  ${List},
  ${List} svg {
    color: #fff;
  }
`;

export const Label = styled.strong`
  display: inline-block;
  padding: 5px 10px;
  border-radius: 20px;
  position: absolute;
  bottom: calc(100% + 5px);
  font-size: 14px;
  font-weight: 600;
  left: 50%;
  transform: translateX(-50%);
  background: #0060c3;
`