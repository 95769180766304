import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  max-width: 1216px;
  margin: 0 auto;
  background: #fefefe;
  box-shadow: 0 20px 56px rgba(30, 31, 91, 0.06);
  border-radius: 4px;
  padding: 50px;

  @media all and (max-width: 1140px) {
    padding: 20px;
  }
`;
export const Title = styled.h2`
  font-weight: 600;
  font-size: 18px;
  line-height: 45px;
  margin-bottom: 10px;

  color: #272727;
`;
export const List = styled.ul`
  display: grid;
  gap: 15px 40px;

  @media all and (min-width: 1141px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media all and (min-width: 700px) and (max-width: 1140px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media all and (max-width: 699px) {
    grid-template-columns: repeat(1, 1fr);
  }

  svg {
    vertical-align: middle;
    margin-right: 4px;
    color: #31d084;
  }
`;

export const Help = styled.div`
  width: 100%;
  max-width: 1216px;
  margin: 40px auto 80px;
  color: #2b343d;
  font-size: 14px;
  font-style: italic;
  padding: 20px;

  a {
    color: #0060c3;
    text-decoration: underline;
  }
`;
