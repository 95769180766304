import React from "react";
import * as S from "./styles";
import Button from "components/Button";
import { FaWhatsapp, FaYoutube, FaYoutubeSquare } from "react-icons/fa";
import {shareWhatsapp} from "../../../helpers/share-helpers";
import {WHATSAPP_NUMBER_FORMATTED} from "../../../constants";

export function KnowMore() {
  return (
    <S.Wrapper>
      <h2>
        Quer saber mais?
        <br />
        Converse com um dos nossos consultores!
      </h2>
      <S.Actions>
        <Button target="_blank" rel="noreferer noopener" as="a" kind="solid" color="success" href={shareWhatsapp(WHATSAPP_NUMBER_FORMATTED)}>
          <FaWhatsapp color="#fff" /> Conversar com um consultor
        </Button>
        <Button target="_blank" rel="noreferer noopener" as="a" href="https://youtu.be/6yD1KbNfACo" kind="solid" color="secondary">
          <FaYoutube color="#ff0000" /> Ver uma demonstração
        </Button>
      </S.Actions>
    </S.Wrapper>
  );
}
