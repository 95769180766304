import React from "react";
import * as Plan from "../components/Plan";
import * as S from "./styles";
import {
  MdArrowRightAlt,
  MdCheck,
  MdHelp,
  MdKeyboardArrowRight,
} from "react-icons/md";
import Button from "components/Button";
import { ActionModals } from "../../../containers/ModalRoot/module";
import { useModalState } from "../../../containers/ModalProvider";
import {useSignup} from "../../../hooks/useSignup";

export function ListPlans() {
  const [, dispatch] = useModalState();
  const { openSignup } = useSignup({
    onOpen: () => {

    }
  });

  return (
    <S.Wrapper>
      <S.Col delay="0s">
        <Plan.Wrapper>
          <Plan.Title>PLANO MENSAL SELF-SERVICE</Plan.Title>
          <Plan.Text>
            Todos os recursos do sistema para você personalizar seu site do seu
            jeito, de forma prática e intuitiva
          </Plan.Text>
          <Plan.Price>
            R$ <strong>129,90</strong> /mês
          </Plan.Price>
          <Plan.PriceInfo>
            Total Anual <MdArrowRightAlt /> <strong>R$ 1.558,80</strong>
          </Plan.PriceInfo>
          <Plan.List>
            <li>
              <MdCheck /> Todos recursos disponíveis
            </li>
            <li>
              <MdCheck /> Suporte via WhatsApp
            </li>
          </Plan.List>

          <Plan.Spacer />

          <Plan.Button onClick={openSignup} color="secondary" kind="solid">
            Experimente 7 dias grátis <MdKeyboardArrowRight />
          </Plan.Button>
        </Plan.Wrapper>
      </S.Col>
      <S.Col delay="0.2s">
        <Plan.Wrapper>
          <Plan.Title>PLANO MENSAL + IMPLANTAÇÃO</Plan.Title>
          <Plan.Text>
            Todos os recursos do sistema com nosso acompanhamento para deixar
            seu site pronto para vendas em tempo recorde
          </Plan.Text>
          <Plan.Price>
            R$ <strong>499,90</strong> /único + R$ <strong>129,90</strong> /mês
          </Plan.Price>
          <Plan.PriceInfo>
            Total Anual <MdArrowRightAlt /> <strong>R$ 2.058,70</strong>
          </Plan.PriceInfo>
          <Plan.List>
            <li>
              <MdCheck /> Todos recursos disponíveis
            </li>
            <li>
              <MdCheck /> Suporte via WhatsApp
            </li>
            <li>
              <MdCheck /> Acompanhamento por time de onboard
              <span
                className="pointer"
                data-position="top"
                onClick={() => {
                  dispatch(ActionModals.openModalImplementation());
                }}
              >
                <MdHelp fill="#0060c3" />
              </span>
            </li>
            <li>
              <MdCheck /> 1 treinamento de 1 hora para sua equipe
            </li>
            <li>
              <MdCheck /> Até 1 hora para ajuste do design do site
            </li>
          </Plan.List>

          <Plan.Spacer />

          <Plan.Button onClick={openSignup} color="secondary" kind="solid">
            Experimente 7 dias grátis <MdKeyboardArrowRight />
          </Plan.Button>
        </Plan.Wrapper>
      </S.Col>
      <S.Col delay="0.4s">
        <Plan.Featured>
          <Plan.Label>MAIS VENDIDO</Plan.Label>
          <Plan.Title>ANUAL + IMPLANTAÇÃO GRÁTIS</Plan.Title>
          <Plan.Text>
            Aproveite todos os recursos do sistema com nosso acompanhamento para
            criar um site perfeito, sem custos extras
          </Plan.Text>
          <Plan.Price>
            Apenas 6 parcelas de R$ <strong>259,80</strong> /ano
          </Plan.Price>
          <Plan.PriceInfo>
            Total Anual <MdArrowRightAlt /> De <del>R$ 2.058,70</del> por{" "}
            <strong>R$ 1.558,80</strong>
          </Plan.PriceInfo>
          <Plan.List>
            <li>
              <MdCheck /> Todos recursos disponíveis
            </li>
            <li>
              <MdCheck /> Suporte via WhatsApp
            </li>
            <li>
              <MdCheck /> Acompanhamento por time de onboard{" "}
              <span
                className="pointer"
                data-position="top"
                onClick={() => {
                  dispatch(ActionModals.openModalImplementation());
                }}
              >
                <MdHelp style={{ marginLeft: "5px" }} />
              </span>
            </li>
            <li>
              <MdCheck /> 2 treinamentos de 1 hora para sua equipe
            </li>
            <li>
              <MdCheck /> Até 2 horas para ajuste do design do site
            </li>
            <li>
              <MdCheck /> 1 ano grátis de Imovelweb***
            </li>
          </Plan.List>

          <Plan.Spacer />

          <Plan.Button onClick={openSignup} color="primary" kind="solid">
            Experimente 7 dias grátis <MdKeyboardArrowRight />
          </Plan.Button>
        </Plan.Featured>
      </S.Col>
    </S.Wrapper>
  );
}
