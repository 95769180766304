import styled from "styled-components";
import Button from "../../../components/Button";

export const Wrapper = styled.div`
  width: 100%;
  max-width: 1216px;
  margin: 45px auto 38px;
  background: #fefefe;
  box-shadow: 0 20px 56px rgba(30, 31, 91, 0.06);
  border-radius: 4px;
  padding: 20px;
  display: flex;
  justify-content: space-between;

  h2 {
    font-size: 36px;
  }

  @media all and (max-width: 1140px) {
    flex-direction: column;
    align-items: center;

    h2 {
      font-size: 22px;
      text-align: center;
      margin-bottom: 15px;
    }
  }
`;

export const Actions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;

  > button,
  > a {
    font-size: 14px;
    font-weight: bold;
    text-align: left !important;
    width: 280px;

    svg {
      font-size: 1.2em;
      vertical-align: middle;
      margin-top: -2px;
      margin-right: 10px;
      margin-left: 15px;
    }
  }
`;
