import React from "react";
import * as S from "./styles";
import { MdCheck, MdCheckCircle, MdHelp } from "react-icons/md";
import { ActionModals } from "../../../containers/ModalRoot/module";
import { useModalState } from "../../../containers/ModalProvider";

const EMAILS_TEXT = `Envie e receba e-mails
  com seu dominio integrado 
  ao Gmail ou Hotmail.
`;

export function AllFeatures() {
  const [, dispatch] = useModalState();

  const openModal = (e) => {
    e.preventDefault();
    dispatch(ActionModals.openModalPortalConditions());
  };

  return (
    <>
      <S.Wrapper>
        <S.Title>Todos os Recursos Inclusos:</S.Title>
        <S.List>
          <li>
            <MdCheckCircle />
            01 Usuário*
          </li>
          <li>
            <MdCheckCircle /> Controle de contas (Financeiro)
          </li>
          <li>
            <MdCheckCircle /> Roteiro de Visitas
          </li>
          <li>
            <MdCheckCircle /> Permissões e Privacidade
          </li>
          <li>
            <MdCheckCircle /> Site Completo
          </li>
          <li>
            <MdCheckCircle /> Notificação via Push no Celular
          </li>
          <li>
            <MdCheckCircle /> Ficha de Visita
          </li>
          <li>
            <MdCheckCircle /> Anexo de Documentos
          </li>
          <li>
            <MdCheckCircle /> Hotsites Ilimitados
          </li>
          <li>
            <MdCheckCircle /> Facebook Leads e Store
          </li>
          <li>
            <MdCheckCircle /> Fichas de Autorização
          </li>
          <li>
            <MdCheckCircle /> Formulário na Galeria de Fotos
          </li>
          <li>
            <MdCheckCircle /> Imóveis Ilimitados
          </li>
          <li>
            <MdCheckCircle /> Diversos Relatórios
          </li>
          <li>
            <MdCheckCircle /> Visão Unificada da Equipe
          </li>
          <li>
            <MdCheckCircle /> Área para Postagens
          </li>
          <li>
            <MdCheckCircle /> Contatos e Leads Ilimitados
          </li>
          <li>
            <MdCheckCircle /> Integração com Portais
          </li>
          <li>
            <MdCheckCircle /> Emails para Proprietário
          </li>
          <li>
            <MdCheckCircle /> Gerador de Cartão Virtual
          </li>
          <li>
            <MdCheckCircle /> Emails Ilimitados
            <span data-tooltip={EMAILS_TEXT} data-position="top">
              <MdHelp fill="#0060c3" style={{ marginLeft: "5px" }} />
            </span>
          </li>
          <li>
            <MdCheckCircle /> Rodízio de Leads para Corretor
          </li>
          <li>
            <MdCheckCircle /> Gerador de Perfil Automático
          </li>
          <li>
            <MdCheckCircle /> PopUp de Recepção no Site
          </li>
          <li>
            <MdCheckCircle /> Aplicativo Completo
          </li>
          <li>
            <MdCheckCircle /> Recebimento de Leads de Portais
          </li>
          <li>
            <MdCheckCircle /> Integrado ao Google Agenda
          </li>
          <li>
            <MdCheckCircle /> Suporte via WhatsApp
          </li>
          <li>
            <MdCheckCircle /> Plugin Whatsapp
          </li>
          <li>
            <MdCheckCircle /> Radar de Clientes por Captação
          </li>
          <li>
            <MdCheckCircle /> Integração com Órulo
          </li>
          <li>
            <MdCheckCircle /> LGPD
          </li>
          <li>
            <MdCheckCircle /> CRM Completo
          </li>
          <li>
            <MdCheckCircle /> Comparador Imóveis
          </li>
          <li>
            <MdCheckCircle /> Marca D’água
          </li>
          <li>
            <MdCheckCircle /> Integração com DWV e Órulo**
          </li>
        </S.List>
      </S.Wrapper>

      <S.Help>
        <p>*Usuários adicionais custam R$19,90 por mês.</p>
        <p>
          **A integração com DWV e/ou Órulo é opcional e custa R$24,90 por mês
          cada um.
        </p>
        <p>
          ***A gratuidade do portal Imovelweb é oferecido pelo portal aos
          clientes do Tecimob.{" "}
          <a href="" onClick={openModal}>
            Ler condições
          </a>
        </p>
      </S.Help>
    </>
  );
}
